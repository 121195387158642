class RuleService {
  rules() {
    return {
      required: value => !!value || "Required",
      min: value => (value || "").length >= 2 || "Min 2 character",
      min8: value => (value || "").length >= 8 || "Min 8 character",
      max: value => (value || "").length <= 20 || "Max 20 character",
      select: value => value.length !== 0 || "Required",
      nonSpec: v =>
        !/([^-a-zA-Z0-9]+)/.test(v) || "Only letter,digit,dash allow",
      noDigit: v => !/([^-a-zA-Z]+)/.test(v) || "Only letter,dash allow"
    };
  }
}

export default new RuleService();
