<template>
  <Segment id="schoolDetail">
    <Ribbon title="School"></Ribbon>
    <v-alert text dense color="#d69075">
      New process: Select school from drop-down list below. If you’re school
      isn’t on this list please contact 1300 236356 and press 1 or email
      enquiries@openingthedoors.org.au
    </v-alert>
    <v-row>
      <v-col cols="9">
        <v-autocomplete
          dense
          outlined
          label="School Name"
          item-text="name"
          item-value="name"
          return-object
          :items="schoolList"
          v-model="schoolSelected"
          placeholder="Type in to search school"
          prepend-inner-icon="mdi-database-search"
          hide-details
        >
          <template v-slot:prepend-inner>
            <span class="red--text">*</span>
          </template>
          <template v-slot:item="{ item }">
            <v-list-item-content>
              <v-list-item-title v-html="item.name"></v-list-item-title>
              <v-list-item-subtitle
                v-html="fullAddress(item)"
              ></v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="3">
        <v-text-field
          readonly
          dense
          outlined
          label="School ID(Doorways)"
          v-model="schoolDetail.doorwayID"
          hide-details
        />
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          readonly
          dense
          outlined
          label="Address Line 1"
          v-model="schoolDetail.addressLine1"
          hide-details
        />
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          readonly
          dense
          outlined
          label="Address Line 2"
          v-model="schoolDetail.addressLine2"
          hide-details
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6">
        <v-text-field
          readonly
          dense
          outlined
          label="Suburb"
          v-model="schoolDetail.suburb"
          hide-details
        >
        </v-text-field>
      </v-col>
      <v-col cols="6" sm="3">
        <v-text-field
          readonly
          dense
          outlined
          label="Postcode"
          v-model="schoolDetail.postcode"
          hide-details
        >
        </v-text-field>
      </v-col>
      <v-col cols="6" sm="3">
        <v-text-field
          readonly
          dense
          outlined
          label="State"
          v-model="schoolDetail.territoryState"
          hide-details
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6">
        <v-text-field
          readonly
          dense
          outlined
          label="Contact Number"
          v-model="schoolDetail.contactNumber"
          hide-details
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          readonly
          dense
          outlined
          label="Email"
          v-model="schoolDetail.email"
          hide-details
        >
        </v-text-field>
      </v-col>
    </v-row>

    <div class="my-6"></div>
    <Ribbon title="Principal"></Ribbon>
    <v-row>
      <v-col cols="12" sm="6" md="4">
        <v-text-field
          dense
          outlined
          label="First Name"
          v-model="schoolDetail.principalFirstName"
          hide-details
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-text-field
          dense
          outlined
          label="Last Name"
          v-model="schoolDetail.principalLastName"
          hide-details
        >
        </v-text-field>
      </v-col>
    </v-row>
    <div class="my-6"></div>
    <Ribbon title="Contact Person"></Ribbon>
    <v-row>
      <v-col cols="12" sm="6" md="4">
        <v-text-field
          dense
          outlined
          label="First Name"
          v-model="schoolDetail.contactFirstName"
          hide-details
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-text-field
          dense
          outlined
          label="Last Name"
          v-model="schoolDetail.contactLastName"
          hide-details
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" md="4">
        <v-text-field
          dense
          outlined
          label="Contact Email"
          v-model="schoolDetail.contactEmail"
          hide-details
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-text-field
          dense
          outlined
          label="Contact Phone"
          v-model="schoolDetail.contactPhone"
          hide-details
        >
        </v-text-field>
      </v-col>
    </v-row>
    <div class="my-6"></div>

    <Ribbon title="Other"></Ribbon>
    <v-row>
      <v-col md="6">
        <v-select
          dense
          outlined
          label="Boarding"
          :items="['YES', 'NO']"
          v-model="schoolDetail.boarding"
          :rules="[rules.required]"
          hide-details
        >
          <template v-slot:prepend-inner>
            <span class="red--text">*</span>
          </template>
        </v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="6">
        <v-select
          dense
          outlined
          :items="gradeList"
          :label="`Year/Grade that student is enrolled in ` + currentGrantYear"
          v-model="schoolDetail.gradeCGY"
          :rules="[rules.required]"
          hide-details
        >
          <template v-slot:prepend-inner>
            <span class="red--text">*</span>
          </template>
        </v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="6">
        <v-text-field
          dense
          outlined
          :label="`School name that student attended in ` + lastGrantYear"
          v-model="schoolDetail.schoolLGY"
          :rules="[rules.required]"
          hide-details
        >
          <template v-slot:prepend-inner>
            <span class="red--text">*</span>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="6">
        <v-select
          dense
          outlined
          item-text="text"
          item-value="value"
          label="Is this application part of a bulk school application"
          :items="['YES', 'NO']"
          v-model="schoolDetail.isBulk"
          :rules="[rules.required]"
          hide-details
        >
          <template v-slot:prepend-inner>
            <span class="red--text">*</span>
          </template>
        </v-select>
      </v-col>
    </v-row>
    <div class="my-6"></div>
  </Segment>
</template>

<script>
import Segment from "@/components/Segment";
import Ribbon from "@/components/Ribbon";
import ChoreData from "@/services/chore.data";
import RulesService from "@/services/rules";
export default {
  name: "SchoolDetail",
  components: { Ribbon, Segment },
  data: () => ({
    rules: RulesService.rules(),
    schoolDetail: {
      doorwayID: "",
      name: "",
      addressLine1: "",
      addressLine2: "",
      suburb: "",
      postcode: "",
      territoryState: "",
      contactNumber: "",
      email: "",
      contactFirstName: "",
      contactLastName: "",
      contactEmail: "",
      contactPhone: "",
      principalFirstName: "",
      principalLastName: "",
      gradeCGY: "",
      schoolLGY: "",
      boarding: "",
      isBulk: ""
    },
    schoolList: ChoreData.schoolList(),
    gradeList: ChoreData.gradeList(),
    schoolSelected: null
  }),
  computed: {
    currentGrantYear() {
      return this.$store.getters["setting/CURRENT_GRANT_YEAR"];
    },
    lastGrantYear() {
      return this.$store.getters["setting/CURRENT_GRANT_YEAR"] - 1;
    }
  },
  watch: {
    schoolSelected(val) {
      if (typeof val === "object" && val !== null) {
        this.autofill(val);
      }
    },
    schoolDetail: {
      handler(val) {
        this.$store.commit("UPDATE_SCHOOL_DETAIL", val);
      },
      deep: true
    }
  },
  methods: {
    fullAddress(school) {
      return (
        school.address1 +
        " " +
        school.suburb +
        " " +
        school.state +
        school.postcode
      );
    },
    //ss represent the school selected by user
    autofill(ss) {
      this.schoolDetail.doorwayID = ss.schoolID;
      this.schoolDetail.name = this.nullCk(ss, "name");
      this.schoolDetail.addressLine1 = this.nullCk(ss, "address1");
      this.schoolDetail.addressLine2 = "";
      this.schoolDetail.suburb = this.nullCk(ss, "suburb");
      this.schoolDetail.postcode = this.nullCk(ss, "postcode");
      this.schoolDetail.territoryState = this.nullCk(ss, "state");
      this.schoolDetail.contactNumber = this.nullCk(ss, "schoolPhone");
      this.schoolDetail.email = this.nullCk(ss, "schoolEmail");
      const tempPrincipal = this.nullCk(ss, "principal");
      if (tempPrincipal !== "") {
        const tempSplit = tempPrincipal.split(" ").slice(-2);
        this.schoolDetail.principalFirstName = tempSplit[0];
        this.schoolDetail.principalLastName = tempSplit[1];
      } else {
        this.schoolDetail.principalFirstName = "";
        this.schoolDetail.principalLastName = "";
      }
    },
    nullCk(entity, propertyName) {
      if (Object.prototype.hasOwnProperty.call(entity, propertyName)) {
        return entity[propertyName];
      } else {
        return "";
      }
    }
  }
};
</script>

<style scoped></style>
