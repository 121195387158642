<template>
  <v-card
    outlined
    class="dark-orange-border rounded-lg mt-4 mx-auto tp"
    max-width="1100px"
  >
    <div class="mx-4">
      <template v-if="$slots.title">
        <v-card-title class="pt-8 pb-6 justify-center font-weight-bold">
          <slot name="title"></slot>
        </v-card-title>
      </template>
      <v-card-text>
        <slot></slot>
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "Segment"
};
</script>

<style scoped>
.tp {
  opacity: 0.95;
}

.dark-orange-border {
  border: 2px solid #d48a6e !important;
}
</style>
