import Vue from "vue";
import Vuex from "vuex";
import {setting} from "@/store/setting";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        application: {
            specifyGrantYear: null,
            isNew: null,
            student: null,
            guardian: null,
            school: null,
            meta: {
                subsidies: null,
                grantAllocation: {
                    selection: null,
                    items: {
                        schoolItems: null,
                        supplierItems: null
                    }
                },
                progressReport: null,
                attachment: null,
                signature: null
            }
        },
        coaFile: null
    },
    mutations: {
        UPDATE_SPECIFY_GRANT_YEAR(state, payload) {
            state.application.specifyGrantYear = payload;
        },
        SET_APPLY_TYPE_NEW(state) {
            state.application.isNew = true;
        },
        SET_APPLY_TYPE_RETURN(state) {
            state.application.isNew = false;
        },
        RESELECT(state) {
            state.application.isNew = null;
        },
        UPDATE_COA_FILE(state, payload) {
            state.coaFile = payload;
        },
        UPDATE_ATTACHMENT_URL(state, payload) {
            state.application.meta.attachment = payload;
        },
        INIT_SCHOOL_ITEM(state) {
            //Remove existing supplier items
            state.application.meta.grantAllocation.items.supplierItems = null;
            if (state.application.meta.grantAllocation.items.schoolItems === null) {
                state.application.meta.grantAllocation.items.schoolItems = [];
                state.application.meta.grantAllocation.items.schoolItems.push({
                    category: null,
                    amount: null,
                    dueDate: null
                });
            }
        },
        INIT_SUPPLIER_ITEM(state) {
            //Remove existing school items
            state.application.meta.grantAllocation.items.schoolItems = null;
            if (state.application.meta.grantAllocation.items.supplierItems === null) {
                state.application.meta.grantAllocation.items.supplierItems = [];
                state.application.meta.grantAllocation.items.supplierItems.push({
                    supplier: null,
                    category: null,
                    amount: null,
                    dueDate: null
                });
            }
        },
        ADD_SCHOOL_ITEM(state) {
            state.application.meta.grantAllocation.items.schoolItems.push({
                category: null,
                amount: null,
                dueDate: null
            });
        },
        ADD_SUPPLIER_ITEM(state) {
            state.application.meta.grantAllocation.items.supplierItems.push({
                supplier: null,
                category: null,
                amount: null,
                dueDate: null
            });
        },
        REMOVE_SCHOOL_ITEM(state, index) {
            if (index !== 0) {
                state.application.meta.grantAllocation.items.schoolItems.splice(
                    index,
                    1
                );
            }
        },
        REMOVE_SUPPLIER_ITEM(state, index) {
            if (index !== 0) {
                state.application.meta.grantAllocation.items.supplierItems.splice(
                    index,
                    1
                );
            }
        },
        UPDATE_STUDENT_DETAIL(state, payload) {
            state.application.student = payload;
        },
        UPDATE_GUARDIAN_DETAIL(state, payload) {
            state.application.guardian = payload;
        },
        UPDATE_SCHOOL_DETAIL(state, payload) {
            state.application.school = payload;
        },
        UPDATE_GRANT_ALLOCATION_SELECTION(state, selection) {
            state.application.meta.grantAllocation.selection = selection;
        },
        UPDATE_PROGRESS_REPORT(state, progressReport) {
            state.application.meta.progressReport = progressReport;
        },
        UPDATE_SUBSIDIES(state, subsidies) {
            state.application.meta.subsidies = subsidies;
        },
        UPDATE_SIGNATURE(state, payload) {
            state.application.meta.signature = payload;
        }
    },
    actions: {},
    modules: {
        setting
    },
    getters: {
        specifyGrantYear: state => {
            return state.application.specifyGrantYear;
        },
        student: state => {
            return state.application.student;
        },
        guardian: state => {
            return state.application.guardian;
        },
        isNew: state => {
            return state.application.isNew === true;
        },
        isSelected: state => {
            //if the application.isNew is not null then means the type has been selected
            return state.application.isNew !== null;
        },
        schoolItems: state => {
            return state.application.meta.grantAllocation.items.schoolItems;
        },
        supplierItems: state => {
            return state.application.meta.grantAllocation.items.supplierItems;
        },
        coaFile: state => {
            return state.coaFile;
        },
        schoolItemsTotalAmount: state => {
            let totalAmount = 0.0;
            state.application.meta.grantAllocation.items.schoolItems.forEach(item => {
                if (item.amount === null) {
                    totalAmount += 0.0;
                } else {
                    totalAmount += parseFloat(item.amount);
                }
            });
            return totalAmount.toFixed(2);
        },
        supplierItemsTotalAmount: state => {
            let totalAmount = 0.0;
            state.application.meta.grantAllocation.items.supplierItems.forEach(
                item => {
                    if (item.amount === null) {
                        totalAmount += 0.0;
                    } else {
                        totalAmount += parseFloat(item.amount);
                    }
                }
            );
            return totalAmount.toFixed(2);
        },
        IS_STUDENT_SECTION_COMPLETED: state => {
            if (state.application.student === null) {
                return false;
            }
            //Check if the student part is completed
            const student = state.application.student;
            return !(
                student.outOfHomeCare === "" ||
                student.birthday === "" ||
                student.firstName === "" ||
                student.lastName === "" ||
                student.gender === ""
            );
        },
        IS_GUARDIAN_SECTION_COMPLETED: state => {
            if (state.application.guardian === null) {
                return false;
            }
            //Check if the guardian part is completed
            const guardian = state.application.guardian;
            if (
                guardian.type === "" ||
                guardian.firstName === "" ||
                guardian.lastName === "" ||
                guardian.addressLine1 === "" ||
                guardian.postcode === "" ||
                guardian.suburb === "" ||
                guardian.territoryState === ""
            ) {
                return false;
            }
            //Contact number or email must filled in at least one of them
            return !(guardian.contactNumber === "" && guardian.email === "");
        },
        IS_COA_SELECTED: state => {
            return state.coaFile !== null;
        },
        IS_PROGRESS_REPORT_COMPLETED: state => {
            let rt = true;
            state.application.meta.progressReport.forEach(pr => {
                if (pr.answer === "") {
                    rt = false;
                }
            });
            return rt;
        },
        IS_SCHOOL_DETAIL_COMPLETED: state => {
            if (state.application.school === null) {
                return false;
            }
            const school = state.application.school;
            return !(
                school.name === "" ||
                school.addressLine1 === "" ||
                school.postcode === "" ||
                school.suburb === "" ||
                school.territoryState === "" ||
                school.boarding === "" ||
                school.gradeCGY === "" ||
                school.schoolLGY === ""
            );
        },
        IS_SUBSIDIES_COMPLETED: state => {
            if (state.application.meta.subsidies === null) {
                return false;
            }
            for (
                let i = 0, len = state.application.meta.subsidies.length;
                i < len;
                i++
            ) {
                if (state.application.meta.subsidies[i].answer === null) {
                    return false;
                }
            }
            return true;
        },
        IS_GRANT_ALLOCATION_COMPLETED: (state, getters) => {
            if (state.application.meta.grantAllocation.selection === null) {
                return false;
            }

            if (getters.schoolItems != null) {
                for (let i = 0, len = getters.schoolItems.length; i < len; i++) {
                    if (
                        getters.schoolItems[i].category === null ||
                        getters.schoolItems[i].amount === null
                    ) {
                        return false;
                    }
                }
            }

            if (getters.supplierItems != null) {
                for (let i = 0, len = getters.supplierItems.length; i < len; i++) {
                    if (
                        getters.supplierItems[i].supplier === null ||
                        getters.supplierItems[i].category === null ||
                        getters.supplierItems[i].amount === null
                    ) {
                        return false;
                    }
                }
            }

            return true;
        }
    }
});
