<template>
  <Segment v-show="typeSelect">
    <v-row class="justify-space-around">
      <v-hover v-slot:default="{ hover }">
        <v-col class="text-center ml-md-10">
          <v-card
            class="pa-auto"
            outlined
            max-width="550px"
            :class="{ 'on-hover orange-shadow': hover }"
            @click="applicantIsNew"
          >
            <v-container>
              <v-row justify="center">
                <v-col cols="auto">
                  <v-img src="@/assets/dry-tree.png" max-width="96px"></v-img>
                </v-col>
              </v-row>
              <p class="body-1 grey--text text--darken-2 pt-4">
                This student has not received a grant from the Foundation before
              </p>
            </v-container>
          </v-card>
        </v-col>
      </v-hover>
      <v-col class="text-center d-none d-md-block" cols="1"> </v-col>
      <v-hover v-slot:default="{ hover }">
        <v-col class="text-center mr-md-10">
          <v-card
            class="pa-auto"
            outlined
            max-width="550px"
            :class="{ 'on-hover orange-shadow': hover }"
            @click="applicantIsReturn"
          >
            <v-container>
              <v-row justify="center">
                <v-col cols="auto">
                  <v-img src="@/assets/sun.png" max-width="96px"></v-img>
                </v-col>
              </v-row>
              <p class="body-1 grey--text text--darken-2 pt-4">
                This student has received a grant from the Foundation before
              </p>
            </v-container>
          </v-card>
        </v-col>
      </v-hover>
    </v-row>
  </Segment>
</template>

<script>
import Segment from "@/components/Segment";
export default {
  name: "ApplySelect",
  components: { Segment },
  data() {
    return {
      hover: true,
      typeSelect: true
    };
  },
  methods: {
    applicantIsNew() {
      this.typeSelect = false;
      this.$store.commit("SET_APPLY_TYPE_NEW");
      this.$emit("moveTop");
    },
    applicantIsReturn() {
      this.typeSelect = false;
      this.$store.commit("SET_APPLY_TYPE_RETURN");
      this.$emit("moveTop");
    }
  }
};
</script>

<style scoped>
.orange-shadow {
  box-shadow: 0 7px 8px -4px rgba(189, 93, 56, 0.2),
    0 12px 17px 2px rgba(189, 93, 56, 0.14),
    0 5px 22px 4px rgba(189, 93, 56, 0.12) !important;
}
.v-card:focus:before {
  color: #d48a6e !important;
}
</style>
