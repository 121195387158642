import axios from "axios";
import supplier from "@/assets/supplier.json";
import school from "@/assets/school.json";

const BASE_URL = process.env.VUE_APP_BACKEND_URL;

class ChoreData {
    stateList() {
        return ["VIC", "NSW", "ACT", "QLD", "SA", "WA", "TAS", "NT", "JBT"];
    }

    categoryList() {
        return ["Books", "Uniform", "Levies", "IT", "School Shoes", "Sport Shoes"];
    }

    supplierList() {
        return supplier;
    }

    schoolList() {
        return school;
    }

    gradeList() {
        return [
            "PREP",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12"
        ];
    }

    systemSetting() {
        return axios.get(BASE_URL + "/setting");
    }

    upload(grantYear, coaFile) {
        let bodyFormData = new FormData();
        bodyFormData.append("file", coaFile);
        return axios({
            method: "post",
            url: BASE_URL + "/file/upload/" + grantYear,
            data: bodyFormData,
            headers: {"Content-Type": "multipart/form-data"}
        });
    }

    submit(body) {
        return axios({
            method: "post",
            url: BASE_URL + "/application/submit",
            data: body
        });
    }
}

export default new ChoreData();
