<template>
  <div class="ml-n8">
    <v-sheet
      color="#d48a6e"
      max-width="300px"
      min-height="25px"
      class="pl-8 white--text text-h6"
    >
      <v-row align="center" justify="center" dense>
        <v-col
          ><div class="">{{ title }}</div>
        </v-col>
      </v-row>
    </v-sheet>
    <v-divider class="mb-6 mt-1"></v-divider>
  </div>
</template>

<script>
export default {
  name: "Ribbon",
  props: {
    title: {
      type: String,
      default: "Sub Title Here"
    }
  }
};
</script>

<style scoped></style>
