import axios from "axios";

const AZURE_BASE_URL =
  "https://atlas.microsoft.com/search/address/json?typeahead=true&api-version=1&language=en-AU&countrySet=AU&view=Auto&subscription-key=";
const SUBSCRIPTION_KEY = "dAHCh4UAOq6F7YfzWx_18Ae9w0j5bqD15YrRsSb5TYI";

class SearchService {
  azureAPI(query) {
    return axios.get(AZURE_BASE_URL + SUBSCRIPTION_KEY + "&query=" + query);
  }
}

export default new SearchService();
