<template>
  <Segment id="subsidies">
    <Ribbon title="Subsidies"></Ribbon>
    <v-alert dense text color="#d69075">
      This section does not affect your child’s eligibility for an Opening The
      Doors Foundation grant.
    </v-alert>

    <v-row>
      <v-col cols="12" md="6" v-for="(item, index) in subsidies" :key="index">
        <v-select
          dense
          outlined
          :label="item.question"
          :items="['YES', 'NO']"
          v-model="item.answer"
          hide-details
        >
          <template v-slot:prepend-inner>
            <span class="red--text">*</span>
          </template>
        </v-select>
      </v-col>
    </v-row>
    <div class="my-6"></div>
    <!--    <v-row>-->
    <!--      <v-col cols="12" md="6" v-for="(item, index) in subsidies" :key="index">-->
    <!--        <v-card outlined>-->
    <!--          <v-card-text>-->
    <!--            <v-radio-group-->
    <!--              v-model="item.answer"-->
    <!--              :label="item.question"-->
    <!--              column-->
    <!--              class="mt-0"-->
    <!--            >-->
    <!--              <v-radio-->
    <!--                label="YES"-->
    <!--                value="YES"-->
    <!--                color="light-green lighten-2"-->
    <!--              ></v-radio>-->
    <!--              <v-radio label="NO" value="NO" color="red lighten-2"></v-radio>-->
    <!--            </v-radio-group>-->
    <!--          </v-card-text>-->
    <!--        </v-card>-->
    <!--      </v-col>-->
    <!--    </v-row>-->
  </Segment>
</template>

<script>
import Segment from "@/components/Segment";
import Ribbon from "@/components/Ribbon";
export default {
  name: "Subsidies",
  components: { Ribbon, Segment },
  data() {
    return {
      subsidies: [
        { question: "Are you eligible for Parenting Payment?", answer: null },
        { question: "Are you eligible for ABSTUDY?", answer: null },
        {
          question: "Are you eligible for Family Tax Benefit A/B?",
          answer: null
        },
        {
          question: "Have/Do you intend to apply for funding elsewhere?",
          answer: null
        }
      ]
    };
  },
  watch: {
    subsidies: {
      handler: function(val) {
        this.$store.commit("UPDATE_SUBSIDIES", val);
      },
      deep: true
    }
  }
};
</script>

<style scoped></style>
