<template>
  <Segment>
    <h4>Select the wrong type?<br /></h4>
    No worries,
    <v-btn
      @click="reselect"
      style="color: #d69075"
      text
      class="pa-0 my-0 text-lowercase"
      >go back</v-btn
    >
    and reselect.<br />
  </Segment>
</template>

<script>
import Segment from "@/components/Segment";
export default {
  name: "Reselect",
  components: { Segment },
  methods: {
    reselect() {
      this.$store.commit("RESELECT");
    }
  }
};
</script>

<style scoped></style>
