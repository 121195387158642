export const setting = {
  namespaced: true,
  state: {
    progressReportQuestions: [],
    currentGrantYear: null
  },
  actions: {},
  mutations: {
    UPDATE_PROGRESS_REPORT_QUESTIONS(state, payload) {
      state.progressReportQuestions = payload;
    },
    UPDATE_CURRENT_GRANT_YEAR(state, payload) {
      state.currentGrantYear = payload;
    }
  },
  getters: {
    PROGRESS_REPORT_QUESTIONS: state => {
      return state.progressReportQuestions;
    },
    CURRENT_GRANT_YEAR: state => {
      return state.currentGrantYear;
    }
  }
};
