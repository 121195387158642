<template>
  <Segment id="progressReport">
    <Ribbon title="ProgressReport"></Ribbon>
    <v-textarea
      v-for="(item, index) in progressReport"
      :key="index"
      outlined
      :label="item.question"
      v-model="item.answer"
    ></v-textarea>
  </Segment>
</template>

<script>
import Ribbon from "@/components/Ribbon";
import Segment from "@/components/Segment";
export default {
  name: "ProgressReport",
  components: { Segment, Ribbon },
  data() {
    return {
      progressReport: this.$store.state.application.meta.progressReport
    };
  },
  watch: {
    progressReport: {
      handler(val) {
        this.$store.commit("UPDATE_PROGRESS_REPORT", val);
      },
      deep: true
    }
  }
};
</script>

<style scoped></style>
