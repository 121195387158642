<template>
  <li>
    <v-row>
      <v-col cols="11">
        <v-combobox
          :items="supplierList"
          v-model="item.supplier"
          outlined
          dense
          label="Supplier"
          hide-details
          :rules="[rules.required]"
        ></v-combobox>
      </v-col>
      <v-col xs="6" sm="6" md="4">
        <v-select
          :items="categoryList"
          v-model="item.category"
          outlined
          dense
          label="Category"
          hide-details
          :rules="[rules.required]"
        ></v-select>
      </v-col>
      <v-col xs="6" sm="4" md="4">
        <v-text-field
          v-model="item.amount"
          class="inputAmount"
          type="number"
          outlined
          dense
          label="$ Amount"
          hide-details
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>
      <v-col xs="6" sm="4" md="3">
        <v-text-field
          label="Due Date"
          placeholder="dd/mm/yyyy"
          outlined
          dense
          hide-details
          v-model="item.dueDate"
        ></v-text-field>
      </v-col>
      <v-col cols="1" v-if="id !== 0">
        <v-btn icon color="red" @click="remove">
          <v-icon>mdi-delete-forever-outline</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </li>
</template>

<script>
import ChoreData from "@/services/chore.data";
import RulesService from "@/services/rules";
export default {
  name: "GrantSupplierRow",
  props: {
    id: Number,
    item: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      rules: RulesService.rules(),
      supplierList: ChoreData.supplierList(),
      categoryList: ChoreData.categoryList()
    };
  },
  methods: {
    remove() {
      this.$emit("remove");
    },
    updateDueDate(dueDate) {
      this.item.dueDate = dueDate;
    }
  }
};
</script>

<style scoped></style>
