<template>
  <Segment id="conditionsOfApproval">
    <Ribbon title="Conditions of Approval"></Ribbon>
    <ul>
      <li class="black--text mt-2">
        The application must include a Confirmation of Aboriginality, if not
        already on file at OTDF.
      </li>
      <li class="black--text mt-2">
        The student must be enrolled in a Catholic or Independent educational
        institution within th estate boundaries of Victoria.
      </li>
      <li class="black--text mt-2">
        The student must be in grade Prep to 12.
      </li>
      <li class="black--text mt-2">
        The student can only submit one regular grant app per year.
      </li>
      <li class="black--text mt-2">
        All sections of the application must be completed.
      </li>
      <li class="black--text mt-2">
        Applications must be submit by 8th of April.
      </li>
      <li class="black--text mt-2">
        The grant amount will not exceed the total of all invoice or the amount
        allocated to the student's grade.
      </li>
      <li class="black--text mt-2">Grants do not cover school fees.</li>
      <li class="black--text mt-2">
        Supplies must be collected by 31st of May.
      </li>
    </ul>

    <div class="ml-2 pt-4">
      <v-text-field
          v-model="signature"
          label="Signature(First name & last name) of primary parent or guardian"
          outlined
          dense
          hide-details
      >
      </v-text-field>
      <v-checkbox
          v-model="tick"
          label="I/We agree to the conditions of grant approval and have complete all sections of the application."
      ></v-checkbox>

      <v-btn
          color="#d48a6e"
          depressed
          class="white--text"
          @click="validate"
          :disabled="disBtn"
      >Submit
      </v-btn
      >
    </div>

    <v-dialog v-model="dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">
          Submit success
        </v-card-title>
        <v-card-text
        >We have successfully received your application, there will be a
          confirmation email sent to your email address.
        </v-card-text
        >
        <v-card-text>
          Please also check your Spam folder just in case the confirmation email
          got delivered there instead of your inbox. If so, select the
          confirmation message and click Not Spam, which will allow future
          messages to get through
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="backToHome">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </Segment>
</template>

<script>
import ChoreService from "@/services/chore.data";
import Segment from "@/components/Segment";
import Ribbon from "@/components/Ribbon";

export default {
  name: "TAC",
  components: {Ribbon, Segment},
  data() {
    return {
      signature: null,
      tick: null,
      disBtn: false,
      dialog: false
    };
  },
  watch: {
    signature(val) {
      this.$store.commit("UPDATE_SIGNATURE", val);
    }
  },
  methods: {
    backToHome() {
      window.location.href = "/";
    },
    validate() {
      //disable the button to avoid multiple click
      this.disBtn = true;
      //Check if the student info section is complete
      if (!this.$store.getters.IS_STUDENT_SECTION_COMPLETED) {
        this.errorToast("Please complete and check the student info section");
        this.goToWithOffset("#applicantDetail", 150);
        this.disBtn = false;
        return;
      }

      //Check if the applicant info section is complete
      if (!this.$store.getters.IS_GUARDIAN_SECTION_COMPLETED) {
        this.errorToast("Please complete and check the guardian/parent info section");
        this.goToWithOffset("#applicantDetail", -200);
        this.disBtn = false;
        return;
      }

      //Check if the school section is completed
      if (!this.$store.getters.IS_SCHOOL_DETAIL_COMPLETED) {
        this.errorToast("Please complete and check the school info section");
        this.goToWithOffset("#schoolDetail", 150);
        this.disBtn = false;
        return;
      }

      //Check if the student/family is the first time apply then Confirmation of Aboriginality is required
      if (this.$store.getters.isNew) {
        if (!this.$store.getters.IS_COA_SELECTED) {
          this.errorToast(
              "Please upload your Confirmation of Aboriginality file"
          );
          this.goToWithOffset("#applicantDetail", -300);
          this.disBtn = false;
          return;
        }
      }
      //Else the progress report is required for the old student/family
      else {
        if (!this.$store.getters.IS_PROGRESS_REPORT_COMPLETED) {
          this.errorToast("Please complete and check the progress report");
          this.goToWithOffset("#progressReport", 50);
          this.disBtn = false;
          return;
        }
      }

      //Check if the subsidies section is completed
      if (!this.$store.getters.IS_SUBSIDIES_COMPLETED) {
        this.errorToast("Please complete and check the subsidies section");
        this.goToWithOffset("#subsidies", 150);
        this.disBtn = false;
        return;
      }

      //Check if the grant allocation is completed
      if (!this.$store.getters.IS_GRANT_ALLOCATION_COMPLETED) {
        this.errorToast(
            "Please complete and check the grant allocation section"
        );
        this.goToWithOffset("#grantAllocation", 150);
        this.disBtn = false;
        return;
      }

      //Check if the signature is signed and tick box checked
      const IS_TAC_COMPLETED = this.signature !== null && this.tick === true;
      if (!IS_TAC_COMPLETED) {
        this.errorToast("Please tick and sign your signature");
        this.goToWithOffset("#conditionsOfApproval", 150);
        this.disBtn = false;
        return;
      }

      if (this.$store.getters.isNew) {
        //Upload the Confirmation of Aboriginality file and attach the url
        const coaFile = this.$store.getters.coaFile;
        const specifyGrantYear = this.$store.getters.specifyGrantYear;
        ChoreService.upload(specifyGrantYear, coaFile).then(response => {
          const status = response.data.status;
          if (status === 200) {
            const returnAttachmentURL = response.data.data.url;
            this.$store.commit("UPDATE_ATTACHMENT_URL", returnAttachmentURL);

            //Attachment is successfully uploaded, now process the application submission
            const application = this.$store.state.application;
            ChoreService.submit(application).then(response => {
              const status = response.data.status;
              if (status === 200) {
                this.disBtn = true;
                this.successToast("Submission success");
                this.dialog = true;
              } else {
                this.disBtn = false;
                this.errorToast(
                    "Submission fail, please contact staff for help"
                );
              }
            });
          } else {
            this.errorToast(
                "Unable to upload the Confirmation of Aboriginality file"
            );
          }
        });
      } else {
        const application = this.$store.state.application;
        ChoreService.submit(application).then(response => {
          const status = response.data.status;
          if (status === 200) {
            this.disBtn = true;
            this.successToast("Submission success");
            this.dialog = true;
          }
        });
      }
    }
  }
};
</script>

<style scoped></style>
