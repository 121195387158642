<template>
  <Segment id="applicantDetail">
    <Ribbon title="Student"></Ribbon>
    <v-row>
      <v-col cols="4">
        <v-select
            label="Specify Grant Year"
            dense
            outlined
            hide-details
            :items="['2020', '2021','2022']"
            v-model="specifyGrantYear"></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" md="4">
        <v-text-field
            dense
            outlined
            label="Student ID"
            placeholder="Staff Use Only"
            v-model="student.doorwayID"
            hide-details
        />
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-select
            dense
            outlined
            label="Out of Home Care"
            :items="['YES', 'NO']"
            v-model="student.outOfHomeCare"
            :rules="[rules.required]"
            hide-details
        >
          <template v-slot:prepend-inner>
            <span class="red--text">*</span>
          </template>
        </v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" md="4">
        <v-text-field
            dense
            outlined
            label="First Name"
            v-model="student.firstName"
            :rules="[rules.required]"
            hide-details
        >
          <template v-slot:prepend-inner>
            <span class="red--text">*</span>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-text-field
            dense
            outlined
            label="Last Name"
            v-model="student.lastName"
            :rules="[rules.required]"
            hide-details
        >
          <template v-slot:prepend-inner>
            <span class="red--text">*</span>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" md="4">
        <v-select
            dense
            outlined
            label="Gender"
            :items="genderList"
            v-model="student.gender"
            :rules="[rules.required]"
            hide-details
        >
          <template v-slot:prepend-inner>
            <span class="red--text">*</span>
          </template>
        </v-select>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                outlined
                dense
                readonly
                v-model="student.birthday"
                label="Date of Birth"
                v-bind="attrs"
                v-on="on"
                :rules="[rules.required]"
                hide-details
            >
              <template v-slot:prepend-inner>
                <span class="red--text">*</span>
              </template>
            </v-text-field>
          </template>
          <v-date-picker
              ref="picker"
              v-model="student.birthday"
              :active-picker.sync="activePicker"
              :max="new Date().toISOString().substr(0, 10)"
              min="1990-01-01"
              @change="save"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>

    <div class="my-6"></div>

    <Ribbon title="Primary Parent or Guardian"></Ribbon>
    <v-row>
      <v-col cols="12" sm="6" md="4">
        <v-text-field
            dense
            outlined
            label="Parent ID"
            placeholder="Staff Use Only"
            v-model="guardian.doorwayID"
            hide-details
        />
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-select
            dense
            outlined
            label="Role Type"
            v-model="guardian.type"
            :items="['Parent', 'Guardian']"
            :rules="[rules.required]"
            hide-details
        >
          <template v-slot:prepend-inner>
            <span class="red--text">*</span>
          </template>
        </v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" md="4">
        <v-text-field
            dense
            outlined
            label="First Name"
            v-model="guardian.firstName"
            :rules="[rules.required]"
            hide-details
        >
          <template v-slot:prepend-inner>
            <span class="red--text">*</span>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-text-field
            dense
            outlined
            label="Last Name"
            v-model="guardian.lastName"
            :rules="[rules.required]"
            hide-details
        >
          <template v-slot:prepend-inner>
            <span class="red--text">*</span>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" md="4">
        <v-text-field
            dense
            outlined
            label="Telephone / Contact Number"
            v-model="guardian.contactNumber"
            :rules="[rules.required]"
            hide-details
        >
          <template v-slot:prepend-inner>
            <span class="red--text">*</span>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-text-field
            dense
            outlined
            label="Email"
            v-model="guardian.email"
            :rules="[rules.required]"
            hide-details
        >
          <template v-slot:prepend-inner>
            <span class="red--text">*</span>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-combobox
            dense
            outlined
            label="Address Search"
            :items="items"
            :search-input.sync="search"
            item-text="freeFormAddress"
            item-value="freeFormAddress"
            placeholder="Search address or manually fill in your address with fields below"
            prepend-inner-icon="mdi-home-search"
            hide-no-data
            hide-selected
            return-object
            hide-details
        >
          <template v-slot:item="data">
            <v-list-item-content @click="autoFill(data.item)">
              <v-list-item-title
                  v-html="getShortAddress(data.item.freeFormAddress)"
              ></v-list-item-title>
              <v-list-item-subtitle
                  v-html="data.item.freeFormAddress"
              ></v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-combobox>
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
            dense
            outlined
            label="Address Line1"
            ref="addressLine2"
            v-model="guardian.addressLine1"
            :rules="[rules.required]"
            hide-details
        >
          <template v-slot:prepend-inner>
            <span class="red--text">*</span>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
            dense
            outlined
            label="Address Line2"
            ref="addressLine2"
            v-model="guardian.addressLine2"
            hide-details
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6">
        <v-text-field
            dense
            outlined
            label="Suburb"
            v-model="guardian.suburb"
            :rules="[rules.required]"
            hide-details
        >
          <template v-slot:prepend-inner>
            <span class="red--text">*</span>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="6" sm="3">
        <v-text-field
            dense
            outlined
            label="Postcode"
            v-model="guardian.postcode"
            :rules="[rules.required]"
            hide-details
        >
          <template v-slot:prepend-inner>
            <span class="red--text">*</span>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="6" sm="3">
        <v-select
            ref="state"
            dense
            outlined
            label="State"
            :items="stateList"
            v-model="guardian.territoryState"
            :rules="[rules.required]"
            hide-details
        >
          <template v-slot:prepend-inner>
            <span class="red--text">*</span>
          </template>
        </v-select>
      </v-col>
    </v-row>
    <div class="my-6"></div>
    <Ribbon v-if="isNew" title="Confirmation of Aboriginality"></Ribbon>
    <v-row v-if="isNew">
      <v-col cols="12" sm="6">
        <v-file-input
            show-size
            dense
            outlined
            accept="image/png, image/jpeg, application/pdf"
            prepend-icon=""
            label="Select Confirmation of Aboriginality File(PDF/Image)"
            v-model="coaFile"
            persistent-hint
            hint="Upload file size maximum 2MB"
        ></v-file-input>
      </v-col>
    </v-row>

    <div class="my-6"></div>
  </Segment>
</template>

<script>
import Segment from "@/components/Segment";
import Ribbon from "@/components/Ribbon";
import SearchService from "@/services/search.service";
import ChoreData from "@/services/chore.data";
import RulesService from "@/services/rules";

export default {
  name: "ApplicantDetail",
  components: {Ribbon, Segment},
  data: () => ({
    specifyGrantYear: null,
    rules: RulesService.rules(),
    coaFile: null,
    activePicker: null,
    date: null,
    menu: false,
    genderList: ["Female", "Male", "Other"],
    student: {
      doorwayID: "",
      outOfHomeCare: "",
      firstName: "",
      lastName: "",
      gender: "",
      birthday: ""
    },
    guardian: {
      doorwayID: "",
      type: "",
      firstName: "",
      lastName: "",
      email: "",
      contactNumber: "",
      addressLine1: "",
      addressLine2: "",
      suburb: "",
      postcode: "",
      territoryState: ""
    },
    items: [],
    search: null,
    select: null,
    stateList: ChoreData.stateList()
  }),
  computed: {
    isNew() {
      return this.$store.getters.isNew;
    }
  },
  watch: {
    specifyGrantYear: {
      handler(val) {
        this.$store.commit("UPDATE_SPECIFY_GRANT_YEAR", val);
      },
      deep: true
    },
    student: {
      handler(val) {
        this.$store.commit("UPDATE_STUDENT_DETAIL", val);
      },
      deep: true
    },
    guardian: {
      handler(val) {
        this.$store.commit("UPDATE_GUARDIAN_DETAIL", val);
      },
      deep: true
    },
    coaFile(val) {
      if (val && val.size > 2048000) {
        this.warningToast(
            "The file's size you selected exceeds the maximum(2MB)"
        );
      }
      this.$store.commit("UPDATE_COA_FILE", val);
    },
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    search(val) {
      if (val && val !== "" && val.length > 4) {
        SearchService.azureAPI(val).then(response => {
          this.items = [];
          const results = response.data.results;
          for (let i = 0, len = results.length; i < len; i++) {
            let address = {
              postcode: results[i].address.postalCode,
              freeFormAddress: results[i].address.freeformAddress,
              suburb: results[i].address.localName,
              state: results[i].address.countrySubdivision
            };
            this.items.push(address);
          }
        });
      }
    }
  },
  methods: {
    save(date) {
      this.$refs.menu.save(date);
    },
    autoFill(addressItem) {
      this.guardian.addressLine1 = this.getShortAddress(
          addressItem.freeFormAddress
      );
      this.guardian.suburb = addressItem.suburb;
      this.guardian.postcode = addressItem.postcode;
      if (addressItem.state === "Victoria") {
        this.guardian.territoryState = "VIC";
      } else if (addressItem.state === "New South Wales") {
        this.guardian.territoryState = "NSW";
      } else if (addressItem.state === "Queensland") {
        this.guardian.territoryState = "QLD";
      } else if (addressItem.state === "Tasmania") {
        this.guardian.territoryState = "TAS";
      } else if (addressItem.state === "South Australia") {
        this.guardian.territoryState = "SA";
      } else if (addressItem.state === "Western Australia") {
        this.guardian.territoryState = "WA";
      } else if (addressItem.state === "Northern Territory") {
        this.guardian.territoryState = "NT";
      } else if (addressItem.state === "Australian Capital Territory") {
        this.guardian.territoryState = "ACT";
      } else {
        this.$refs.state.focus();
      }
    },
    getShortAddress(ffa) {
      return ffa.split(",")[0];
    }
  }
};
</script>

<style scoped></style>
