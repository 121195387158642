<template>
  <v-app>
    <v-navigation-drawer app color="#bd5d38" v-if="isSelected">
      <v-list>
        <v-list-item>
          <img
              alt=""
              src="./assets/OTDFLogo.jpg"
              class="rounded-circle mx-auto mt-8 mb-4"
              style="border: .5rem solid rgba(255,255,255,.2);max-width: 150px"
          />
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item
            link
            v-for="item in isSelected && !isNew ? items : filterItems"
            :key="item.title"
        >
          <v-list-item-content @click="$vuetify.goTo(item.id)">
            <v-list-item-title class="grey--text text--lighten-2 ml-6">
              {{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main :class="{ close: !open }">
      <div v-if="open" class="bg"></div>
      <v-container fill-height>
        <v-layout align-center justify-center>
          <v-flex>
            <div class="text-center" v-if="!fetchFinished">
              <v-card-text>Fetching essential data</v-card-text>
              <v-progress-circular
                  class="text-center"
                  size="64"
                  indeterminate
              ></v-progress-circular>
            </div>

            <div v-if="open">
              <Reselect class="my-8" v-if="isSelected"></Reselect>

              <ApplySelect
                  class="my-8"
                  v-if="!isSelected"
                  @moveTop="moveTop"
              ></ApplySelect>

              <ApplicantDetail class="my-8" v-if="isSelected"></ApplicantDetail>

              <SchoolDetail class="my-8" v-if="isSelected"></SchoolDetail>

              <Subsidies class="my-8" v-if="isSelected"></Subsidies>

              <GrantAllocation class="my-8" v-if="isSelected"></GrantAllocation>

              <ProgressReport
                  class="my-8"
                  v-show="isSelected && !isNew"
              ></ProgressReport>

              <TAC class="my-8" v-if="isSelected"></TAC>

            </div>
            <div v-if="fetchFinished">
              <div v-if="currentGrantYear === null">
                <v-img
                    src="@/assets/close.png"
                    max-height="300px"
                    contain
                    class="my-4"
                ></v-img>
                <h2 class="text-center my-4">
                  Server is down
                </h2>
                <h4 class="text-center my-4">
                  Please contact webmaster
                </h4>
              </div>
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import ApplicantDetail from "@/views/ApplicantDetail";
import ApplySelect from "@/views/ApplySelect";
import ProgressReport from "@/views/ProgressReport";
import TAC from "@/views/TAC";
import Subsidies from "@/views/Subsidies";
import GrantAllocation from "@/views/GrantAllocation";
import SchoolDetail from "@/views/SchoolDetail";
import Reselect from "@/views/Reselect";
import ChoreData from "@/services/chore.data";
export default {
  name: "App",
  components: {
    Reselect,
    SchoolDetail,
    GrantAllocation,
    Subsidies,
    TAC,
    ProgressReport,
    ApplySelect,
    ApplicantDetail
  },
  computed: {
    isSelected() {
      return this.$store.getters.isSelected;
    },
    isNew() {
      return this.$store.getters.isNew;
    },
    filterItems() {
      //No progress report
      return this.items.filter((el, index) => index !== 5);
    }
  },
  data() {
    return {
      open: true,
      items: [
        { title: "Home", id: "#announcement" },
        { title: "Applicant Detail", id: "#applicantDetail" },
        { title: "School Detail", id: "#schoolDetail" },
        { title: "Subsidies", id: "#subsidies" },
        { title: "Grant Allocation", id: "#grantAllocation" },
        { title: "Progress Report", id: "#progressReport" },
        { title: "Conditions of Approval", id: "#conditionsOfApproval" },
        { title: "Contact", id: "#contact" }
      ],
      progressReportQuestions: null,
      currentGrantYear: null,
      fetchFinished: false
    };
  },
  methods: {
    initialize() {
      ChoreData.systemSetting()
          .then(response => {
            const status = response.data.status;
            if (status === 200) {
              this.fetchFinished = true;
              const metaData = response.data.data;
              this.$store.commit(
                  "setting/UPDATE_PROGRESS_REPORT_QUESTIONS",
                  JSON.parse(metaData.progressReport)
              );

              const questions = this.$store.getters[
                  "setting/PROGRESS_REPORT_QUESTIONS"
                  ];
              const pr = [];
              for (let i = 0, len = questions.length; i < len; i++) {
                pr.push({
                  question: questions[i],
                  answer: ""
                });
              }
              this.$store.commit("UPDATE_PROGRESS_REPORT", pr);

              this.$store.commit(
                  "setting/UPDATE_CURRENT_GRANT_YEAR",
                  metaData.currentGrantYear
              );
              this.currentGrantYear = metaData.currentGrantYear;
            } else {
              this.errorToast("Unknown error, please contact the webmaster");
            }
          })
          .catch(() => {
            this.fetchFinished = true;
            this.errorToast(
                "Server is not responding, please contact the webmaster"
            );
          });
    },
    moveTop() {
      //The timeout here is to give some time for vue to render the components otherwise the scroll will fail
      setTimeout(() => this.goToWithOffset("#applicantDetail", 150), 300);
    }
  },
  created() {
    this.initialize();
  }
};
</script>

<style scoped>
.bg {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0.3;
  background: url("assets/background_image.png") no-repeat center center;
}
.close {
  background-color: #efedfa;
}
</style>
