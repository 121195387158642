<template>
  <Segment id="grantAllocation">
    <Ribbon title="Grant Allocation"></Ribbon>
    <v-row>
      <v-col>
        <v-select
          dense
          outlined
          label="Where would you like your grant to be sent?"
          v-model="grantSelect"
          :items="['School', 'Supplier', 'Both']"
        >
        </v-select>
      </v-col>
    </v-row>
    <v-card outlined style="border: thin solid #a3a3a3" v-if="grantSelect">
      <v-card-text v-if="grantSelect === 'School'">
        <p class="mb-0">
          Which school costs would you like the grant to be allocated?
        </p>
        <p>
          Write in order of priority
        </p>
        <ol>
          <GrantSchoolRow
            v-for="(item, index) in schoolItems"
            :key="index"
            :id="index"
            :item="item"
            @remove="removeSchoolItem(index)"
          ></GrantSchoolRow>
        </ol>

        <v-divider class="my-6"></v-divider>
        <v-row class="justify-space-between">
          <v-col cols="3">
            <v-text-field
              class="ml-6"
              label="Total Amount"
              readonly
              dense
              outlined
              hide-details
              :value="schoolItemsTotalAmount"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-btn outlined color="success" class="ml-6" @click="addSchoolItem">
              <v-icon left>mdi-plus-box</v-icon>
              Add more
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-if="grantSelect === 'Supplier' || grantSelect === 'Both'">
        <p class="mb-0">
          Which suppliers and school would you like the grant to be allocated?
          (You may include your school as a supplier)
        </p>
        <p>
          Write in order of priority
        </p>
        <v-alert dense text color="#d69075">
          The Foundation has partnerships with suppliers all over Victoria. See
          the suppliers list drop down below. If you would like your grant, or a
          portion of you grant, to go to a supplier which is not on this list,
          we may not be able to approve the grant. We will try our best to
          distribute funds to all suppliers that you list below.
        </v-alert>
        <ol>
          <GrantSupplierRow
            v-for="(item, index) in supplierItems"
            :key="index"
            :id="index"
            :item="item"
            @remove="removeSupplierItem(index)"
          ></GrantSupplierRow>
        </ol>

        <v-divider class="my-6"></v-divider>
        <v-row class="justify-space-between">
          <v-col cols="3">
            <v-text-field
              class="ml-6"
              label="Total Amount"
              readonly
              dense
              outlined
              hide-details
              :value="supplierItemsTotalAmount"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-btn outlined color="success" @click="addSupplierItem">
              <v-icon left>mdi-plus-box</v-icon>
              Add more
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </Segment>
</template>

<script>
import Segment from "@/components/Segment";
import Ribbon from "@/components/Ribbon";
import GrantSchoolRow from "@/components/GrantSchoolRow";
import GrantSupplierRow from "@/components/GrantSupplierRow";

export default {
  name: "GrantAllocation",
  components: { GrantSupplierRow, GrantSchoolRow, Ribbon, Segment },
  data() {
    return {
      grantSelect: null
    };
  },
  watch: {
    grantSelect: function(val) {
      this.$store.commit("UPDATE_GRANT_ALLOCATION_SELECTION", val);
      if (val === "School") {
        this.$store.commit("INIT_SCHOOL_ITEM");
      } else {
        this.$store.commit("INIT_SUPPLIER_ITEM");
      }
    }
  },
  computed: {
    schoolItems() {
      return this.$store.getters.schoolItems;
    },
    supplierItems() {
      return this.$store.getters.supplierItems;
    },
    schoolItemsTotalAmount() {
      return this.$store.getters.schoolItemsTotalAmount;
    },
    supplierItemsTotalAmount() {
      return this.$store.getters.supplierItemsTotalAmount;
    }
  },
  methods: {
    addSchoolItem() {
      this.$store.commit("ADD_SCHOOL_ITEM");
    },
    addSupplierItem() {
      this.$store.commit("ADD_SUPPLIER_ITEM");
    },
    removeSchoolItem(index) {
      this.$store.commit("REMOVE_SCHOOL_ITEM", index);
    },
    removeSupplierItem(index) {
      this.$store.commit("REMOVE_SUPPLIER_ITEM", index);
    }
  }
};
</script>

<style>
.inputAmount input[type="number"] {
  -moz-appearance: textfield;
}
.inputAmount input::-webkit-outer-spin-button,
.inputAmount input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>
