import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import vuetify from "./plugins/vuetify";

Vue.config.productionTip = false;

Vue.mixin({
  methods: {
    successToast(msg) {
      this.$toast.success(msg, { y: "top", timeout: 3000 });
    },
    errorToast(msg) {
      this.$toast.error(msg, { y: "top", timeout: 3000 });
    },
    warningToast(msg) {
      this.$toast.warning(msg, { y: "top", color: "#d69075", timeout: 3000 });
    },
    goToWithOffset(target, offset) {
      this.$vuetify.goTo(target, { offset: offset });
    }
  }
});

new Vue({
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
